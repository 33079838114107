import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import ThemeBox from "../../components/bricks/ThemeBox";

const Features = ({location}) => (
  <DefaultLayout
    title="Beast Mode – No Card left behind!"
    description="Beast Mode highlights uncompleted Cards at the end of a Run, making sure you don’t forget them."
    location={location}
  >
    <Hero size="sm" title="Beast Mode" subline="No Card left behind!" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature
        childAsDescription
        title="Never forget Cards ever again"
        img="timeline/beast-mode-badge.png"
        imgRight
      >
        With Beast Mode, we’ll make sure any leftover Cards from a Run don’t just keep rolling over
        from Run to Run, highlighting them with a Beast Level marker that'll make sure they don't
        just slip by.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="We’ll keep reminding you"
        img="timeline/beast-mode-enabling.png"
      >
        Beast Levels will continue to increase every time a Card misses its deadline, making sure
        that, Run after Run, you won’t forget about it.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Decide the timing and personalize it"
        img="timeline/beast-level-increases.png"
        imgRight
      >
        To start tracking Beast Levels for Cards, you can simply enable Beast Mode for the
        respective Run–or you can automatically enable Beast Mode after a preset amount of time,
        too. This allows your team to have a planning phase to finalize the scope of the Run before
        committing to the contained Cards.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
